import React from 'react';
import cover from './cover2.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={cover} className="App-logo" alt="logo" />
      </header>
      <div className='container font-Optimus-Bold termine'>
        <p>Mittwoch</p>
        <p>17.05.2023</p>
        <p>19:00 h. c. t.</p>
        <p>Begrüßungsabend a. d. H.</p>
        <p>Samstag</p>
        <p>20.05.2023</p>
        <p>18:00 h. c. t.</p>
        <p>Einlass mit Sektempfang</p>
        <p>Donnerstag</p>
        <p>18.05.2023</p>
        <p>15:00 h. c. t.</p>
        <p>Cocktails &amp; Gegrilltes a. d. H.</p>
        <p></p>
        <p></p>
        <p>19:00 h. s. t.</p>
        <p>Festball</p>
        <p>Freitag</p>
        <p>19.05.2023</p>
        <p>18:30 h. s. t.</p>
        <p>Gottesdienst im Kölner Dom</p>
        <p>Sonntag</p>
        <p>21.05.2023</p>
        <p>12:00 h. c. t.</p>
        <p>Umtrunk &amp; Ausklang a. d. H.</p>
        <p></p>
        <p></p>
        <p>20:00 h. s. t.</p>
        <p>Festkommers</p>
      </div>
      <div>
        <p className='font-Optimus-Bold info'>Nähere Informationen zur Anmeldung erhalten Sie unter: </p>
        <p className='font-Optimus-Bold link'><a href="mailto://stiftungsfest@alemannia-koeln.de">stiftungsfest@alemannia-koeln.de</a></p>
      </div>
    </div>
  );
}

export default App;
